




























import { DaoStore } from '@/stores/dao-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class DaoToJoinItem extends Vue {
  @Prop({}) dao!: DaoStore

  goToDaoDetail() {
    this.$router.push(`/community-organize/dao-detail/${this.dao?.daoId}`)
  }
}
