import { render, staticRenderFns } from "./dao-to-join-item.vue?vue&type=template&id=7f49c240&scoped=true&"
import script from "./dao-to-join-item.vue?vue&type=script&lang=ts&"
export * from "./dao-to-join-item.vue?vue&type=script&lang=ts&"
import style0 from "./dao-to-join-item.vue?vue&type=style&index=0&id=7f49c240&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f49c240",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
